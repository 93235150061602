/** @jsx jsx */
import { Skeleton } from '@material-ui/lab'
import { DecisionLabel } from 'components/common'
import MainLayout from 'components/layouts/main'
import { InfoTable } from 'components/patientDetails'
import { PageTitle } from 'components/page-title'
import { ThemedPaper } from 'components/paper'
import SEO from 'components/seo'
import { FC, useMemo } from 'react'
import { Box, Container, Flex, jsx } from 'theme-ui'
import { csrApi } from 'utils/api'
import { formatDate } from 'utils/formatDate'
import { useApi } from 'utils/useApi'

const PatientDetails = props => {
  const selectedTicket = props.location.state?.ticket as Api.ITicket

  if (!selectedTicket) {
    return null
  }
  const isBiosimilar = selectedTicket?.request_type?.toLowerCase().includes("biosimilar") || false;
  const isFacet = selectedTicket?.request_type?.toLowerCase().includes("facet") || false;
  const initialStatus: any = { //  const initialStatus: Api.ITicket = {
  }

  const params = useMemo(() => {
    return {
      ticketId: props.id,
    }
  }, [props.id])

  // Getting this ticket status really may not be necessary anymore, since it's included in the ticket search and getTicketById API endpoints. -BK

  const [ticketStatus, loading] = useApi<Api.ITicket>(
    csrApi.getTicketById,
    initialStatus,
    params
  )

  return (
    <MainLayout>
      <SEO title="Dashboard | Patients" />
      <Container
        sx={{
          mt: -9,
          maxWidth: 1350,
          padding: 0,
          mb: 9,
        }}
      >
        <PageTitle
          title={ticketStatus ? (ticketStatus.facet_status || 'No status set') : 'Updating...'}
          backBtnTitle="Back to Search"
          variant="inverted"
        />
        <Flex
          sx={{
            mt: '25px',
            mx: '-16px',
          }}
        >
          <Col>
            <InfoTable.Row
              title="Patient First Name:"
              value={selectedTicket.patient_first_name}
            />
            <InfoTable.Row
              title="Patient Last Name:"
              value={selectedTicket.patient_last_name}
            />
            <InfoTable.Row title="OTIP ID:" value={selectedTicket.certificate_number} />
            <InfoTable.Row title="Plan:" value={selectedTicket.plan_sponsor} />
            <InfoTable.Row
              title="Patient Address:"
              value={selectedTicket.patient_addresses[0]?.string}
            />
            <InfoTable.Row
              title="Member First Name:"
              value={selectedTicket.plan_member_first_name}
            />
            <InfoTable.Row
              title="Member Last Name:"
              value={selectedTicket.plan_member_last_name}
            />
            <InfoTable.Row
              title="Request Type"
              value={selectedTicket.request_type}
            />
          </Col>
          <Col>
            {isBiosimilar ? (
              <InfoTable.Row
                title="Original Biologic Drug"
                value={selectedTicket.orig_biological_drug}
              />
            ) : (
              <InfoTable.Row
                title="Requested Drug Name"
                value={selectedTicket.requested_drug_name}
              />
            )}

            {isBiosimilar && (
              <InfoTable.Row
              title="Dosage Regimen"
              value={selectedTicket.requested_dosing_regimen}
            />
            )}

            <InfoTable.Row
              title="Claim Received:"
              value={formatDate(selectedTicket.date_created)}
            />
            <InfoTable.Row
              title="Status:"
              // value={loading ? <Skeleton /> : ticketStatus.status}
              value={loading ? <Skeleton /> : ticketStatus.facet_status}
            />
            <InfoTable.Row
              title="Status Updated:"
              value={
                loading ? <Skeleton /> : formatDate(ticketStatus.facet_status_updated_at)
              }
            />
            <InfoTable.Row
              title={isFacet ? "Initial Decision" : "Decision"}
              value={<DecisionValue value={selectedTicket.decision} />}
            />

            {isFacet && selectedTicket.appeal_decision?.id && (
              <InfoTable.Row
                title="Appeal Decision"
                value={<DecisionValue value={selectedTicket.appeal_decision} />}
              />
            )}

            {!isBiosimilar && (
              <InfoTable.Row
                title="Conditionally Approved Drugs:"
                value={selectedTicket.conditionally_approved_drugs}
              />
            )}
            {isBiosimilar && (
              <InfoTable.Row
              title="Approved Biosimilar"
              value={selectedTicket.approved_biosimilar}
            />
            )}
            <InfoTable.Row
              title="Referred to MemberRx?"
              value={selectedTicket.refer_memberrx}
            />
            <InfoTable.Row
              title="Pharmacist:"
              value={selectedTicket.case_manager?.length ? `${selectedTicket.case_manager}` : `${selectedTicket.agent_first_name || ''} ${selectedTicket.agent_last_name || ''}`}
            />
            <InfoTable.Row
              title="Communication method:"
              value={selectedTicket.decision_comm_method}
            />
            <InfoTable.Row
              title="Date letter sent:"
              value={
                selectedTicket.date_resolved?.indexOf('0001-01-01') > -1 // default date from API
                  ? undefined
                  : formatDate(selectedTicket.date_resolved)
              }
            />
          </Col>
        </Flex>
      </Container>
    </MainLayout>
  )
}

const Col: FC = ({ children }) => {
  return (
    <Box
      sx={{
        flex: '0 0 50%',
        px: '16px',
      }}
    >
      <ThemedPaper>
        <InfoTable>{children}</InfoTable>
      </ThemedPaper>
    </Box>
  )
}

const DecisionValue = ({ value }: { value: Api.IDecision }) => (
  <DecisionLabel variant={value as Api.IDecision} />
)

export default PatientDetails
